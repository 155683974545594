import revive_payload_client_4sVQNw7RlN from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_y9dIov5OEk from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/node_modules/nuxt3-vuex-module/src/plugin.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import payload_client_yVLowv6hDl from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import index_eVrIVTfpN2 from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/plugins/filter/index.ts";
import index_6Kk2VIU7BK from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/plugins/storage/index.ts";
import embedScript_U2kb91NCGD from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/plugins/embedScript.ts";
import error_handler_kEP5FliEXj from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/plugins/error-handler.ts";
import extenstions_dwu9IpABDb from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/plugins/extenstions.ts";
import gtm_cXsUZobmSF from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/plugins/gtm.ts";
import infiniteloading_bXJi9Mqah5 from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/plugins/infiniteloading.ts";
import onboarding_kfzip8l3qe from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/plugins/onboarding.ts";
import sentry_client_shVUlIjFLk from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/plugins/sentry.client.ts";
import vue_qrcode_reader_goAiHSkw3q from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/plugins/vue-qrcode-reader.ts";
import vuetify_7h9QAQEssH from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/plugins/vuetify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  plugin_y9dIov5OEk,
  chunk_reload_client_UciE0i6zes,
  payload_client_yVLowv6hDl,
  index_eVrIVTfpN2,
  index_6Kk2VIU7BK,
  embedScript_U2kb91NCGD,
  error_handler_kEP5FliEXj,
  extenstions_dwu9IpABDb,
  gtm_cXsUZobmSF,
  infiniteloading_bXJi9Mqah5,
  onboarding_kfzip8l3qe,
  sentry_client_shVUlIjFLk,
  vue_qrcode_reader_goAiHSkw3q,
  vuetify_7h9QAQEssH
]